import { once } from 'lodash';
import { bindActionCreators } from 'redux';
import { fetchSubcategories } from '../../common/actions/fetch-categories';
import { addBestAnswerAndNotify } from '../actions/add-best-answer';
import { removeBestAnswerAndNotify } from '../actions/remove-best-answer';
import { startEditingComment } from '../actions/start-editing-comment';
import { fetchCategoryPosts } from '../actions/fetch-category-posts';
import { setPostTypeFilter, setCategoryFilter } from '../actions/set-filter';
import { resetPostTypeFilter } from '../actions/reset-filter';
import { subscribeToCategory } from '../actions/subscribe-to-category';
import { unsubscribeFromCategory } from '../actions/unsubscribe-from-category';
import { resolveCommentDeepLinkUrlPromisified } from '../actions/comment-deep-link-actions';
import { fetchLocalStorage } from '../actions/fetch-local-storage';
import { setLocalStorageItem } from '../actions/set-local-storage-item';
import { showJoinCommunityPopup } from '../../common/actions/join-community';
import { pageOpened } from '../actions/page-opened';
import { modalActions } from '../../common/modals/framework/initialize-store';
import search, { clearSearchResults } from '../actions/search';
import { incrementCommentLikeCount } from '../actions/increment-comment-like-count';
import { incrementPostLikeCount } from '../../common/actions/increment-post-like-count';
import { messageActions } from '../../common/messages/framework/initialize-store';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { fetchPostPageData } from '../actions/fetch-post-page-data';
import { incrementPostViewCount } from '../actions/increment-post-view-count';
import { setPostsPageSorting, setSearchPageSorting } from '../actions/set-sorting';
import { setIsLoaded } from '../actions/set-is-loaded';
import { setIsLoading } from '../actions/set-is-loading';
import { subscribeToPost } from '../actions/subscribe-to-post';
import { unsubscribeFromPost } from '../actions/unsubscribe-from-post';
import { pinPost } from '../actions/pin-post';
import { unpinPost } from '../actions/unpin-post';
import { enablePostComments } from '../actions/enable-post-comments';
import { disablePostComments } from '../actions/disable-post-comments';
import { actionsOpened } from '../actions/actions-opened';
import { actionsClosed } from '../actions/actions-closed';
import { buttonClicked } from '../actions/button-clicked';
import { emitOpenCategory, emitCloseCategory } from '../actions/category-socket';
import { emitTypingThrottled, clearTypingUsers } from '../actions/typing-socket';
import { emitClosePost, emitOpenPost } from '../actions/post-socket';
import { removeItemFromUploadedRegistry } from '../actions/uploaded-registry-actions';
import { fetchOembed } from '../actions/fetch-oembed';
import { createPostAndNavigateToItPromisified } from '../actions/create-post';
import { updatePostPromisified } from '../actions/update-post';
import { resetPagerState } from '../actions/comments-pagination-actions';
import {
  createComment,
  createCommentReply,
  createCommentPromisified,
  createCommentReplyPromisified,
} from '../actions/create-comment';
import {
  fetchReplies,
  fetchInitialReplies,
  fetchRepliesToPagePromisified,
  fetchRepliesPromisified,
} from '../actions/fetch-replies';
import { updateCommentPromisified } from '../actions/update-comment';
import { stopEditingComment } from '../actions/stop-editing-comment';
import { fetchCommentsWithReplies } from '../actions/fetch-comments';
import { upvoteComment, downvoteComment, unvoteComment } from '../actions/vote-comment';
import { changePage } from '../actions/change-page';
import { goBack } from '../actions/go-back';
import { clearComments } from '../actions/clear-comments';
import { fastFormSubmitCallback } from '../../common/components/fast-form/store/fast-form-actions';
import { appLoaded } from '../actions/app-loaded';
import { setHasReportedAppLoaded } from '../../common/store/app-loaded/app-loaded-actions';
import { fetchBadges } from '../actions/fetch-badges';
import { fetchPaidPlans } from '../actions/fetch-paid-plans';
import { requestFileDownloadUrlPromisified } from '../../common/services/wix-media/media-api';
import { fileDownloadRequest } from '../actions/file-upload-bi-actions';
import {
  userEventsMakeCategoryChildOrParent,
  userEventsShowEmptyState,
  userEventsReplyOrCommentIntent,
  userEventsClickPublish,
  userEventsClickLike,
  userEventsClickProfileLink,
  userEventsClickCreatePost,
  userEventsClickCreatePostButton,
  userEventsGuidelinesShown,
  userEventsPostingDisabledClose,
  userEventsSearchFocused,
  userEventsSearchCleared,
  userEventsSearchSuggestionsLoaded,
  userEventsSearchSubmitted,
} from '../actions/user-events';
import { requestLoginPromisified, requestLogin } from '../actions/request-login';
import { setSavedAction } from '../../common/store/saved-action/set-saved-action';
import {
  manageCategoryMembersAddSiteMembers,
  manageCategoryMembersRemoveSiteMembers,
} from '../actions/manage-category-members';
import { categoryAddGroups, categoryRemoveGroups } from '../actions/private-category-actions';
import {
  fetchSiteMembersList,
  resetSiteMembersList,
} from '../../common/store/site-members/site-members-actions';
import { assignMembersToGroup } from '../actions/assign-members-to-group';
import { deleteMembersFromGroup } from '../actions/delete-members-from-group';
import {
  fetchMembersGroupsList,
  fetchMembersGroupsListPromisified,
} from '../actions/fetch-members-groups-list';
import { triggerOfflineMigration } from '../actions/offline-migration-actions';
import { fetchCategoriesGroups } from '../../common/actions/fetch-categories-groups';
import { navigateToPaidPlans } from '../actions/navigate-to-paid-plans';
import { fetchLikesDataPromisified, resetLikesData } from '../actions/who-liked-actions';
import { fetchSimilarPosts } from '../actions/fetch-similar-posts';
import { fetchExternalLinks } from '../actions/fetch-external-links';
import {
  onBeforePostCreateHook,
  onBeforePostUpdateHook,
  onFetchExternalLinksHook,
  onBeforeCommentCreateHook,
  onBeforeCommentUpdateHook,
} from './forum-external-hooks';
import { fetchComment } from '../actions/fetch-comment';
import { subscribeToAllCategories } from '../actions/subscribe-to-all-categories';
import { unsubscribeFromAllCategories } from '../actions/unsubscribe-from-all-categories';
import { hideGuidelines, showGuidelines } from '../actions/guidelines';
import { startLoadingCategory } from '../actions/start-loading-category';
import { navigateToMainPage } from '../containers/header-navigation';
import { updateForumData, togglePosting } from '../actions/update-forum-data';
import { fetchSearchSuggestionsPromisified } from '../actions/fetch-search-suggestions';
import { markPostsAsRead, markPostAsRead, markPostAsUnread } from '../containers/user-activity';
// @ts-expect-error
import { SANTA_MEMBERS_APP_ID, CHAT_APP_DEF_ID } from '../constants/applications';
import { FedopsLogger, Sentry, WixCodeApi } from '../types/platform-types';
import { Store } from '../types/store-types';
import { default as BiLocationTrackerConstructor } from '../bi-events/bi-location-tracker';
import { getCurrentUser } from '../../common/store/current-user/current-user-selectors';
import { decorateActionsWithLogger } from '../../common/services/monitoring';

export const createControllerActions = ({
  wixCodeApi,
  store,
  fedopsLogger,
  BiLocationTracker,
  sentry,
}: {
  wixCodeApi: WixCodeApi;
  store: Store;
  fedopsLogger: FedopsLogger;
  BiLocationTracker: typeof BiLocationTrackerConstructor;
  sentry: Sentry;
}) => {
  return {
    captureMessage: sentry ? sentry.captureMessage : () => {},
    interactionStarted: (interaction: string) => fedopsLogger.interactionStarted(interaction),
    interactionEnded: (interaction: string) => fedopsLogger.interactionEnded(interaction),
    startPrivateChat: (uid: string) =>
      wixCodeApi.site.getPublicAPI(CHAT_APP_DEF_ID).then(api => api.startPrivateChat(uid)),
    navigateToSection: (section: string) =>
      wixCodeApi.site
        .getPublicAPI(SANTA_MEMBERS_APP_ID)
        .then(api => api.navigateToSection(section)),
    navigateToMember: ({ memberId, memberSlug }: { memberId: string; memberSlug: string }) =>
      wixCodeApi.site
        .getPublicAPI(SANTA_MEMBERS_APP_ID)
        .then(api => api.navigateToMember({ memberId, memberSlug })),
    logout: () => wixCodeApi.user.logout(),
    navigateToRoot: () => {
      wixCodeApi.location.to?.('/');
    },
    navigateToProfile: ({ memberId, memberSlug }: { memberId: string; memberSlug: string }) => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => {
        api.navigateToMember({ memberId, memberSlug }).catch(console.error);
      });
    },
    navigateToProfileSettings: () => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => {
        const user = getCurrentUser(store.getState());
        api
          .navigateToSection({
            appDefinitionId: '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
            sectionId: 'settings',
            memberId: user.siteMemberId,
            memberSlug: user.slug,
          })
          .catch(console.error);
      });
    },
    navigateToNotifications: () => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => {
        api
          .navigateToSection({
            appDefinitionId: '14f25924-5664-31b2-9568-f9c5ed98c9b1',
            sectionId: 'notifications',
          })
          .catch(console.error);
      });
    },
    renderSEOTags: (data: Parameters<WixCodeApi['seo']['renderSEOTags']>[0]) =>
      wixCodeApi.seo.renderSEOTags(data),
    ...decorateActionsWithLogger(
      bindActionCreators(
        {
          appLoaded: once(appLoaded),
          addBestAnswer: addBestAnswerAndNotify,
          removeBestAnswer: removeBestAnswerAndNotify,
          showJoinCommunityPopup,
          search,
          incrementCommentLikeCount,
          incrementPostLikeCount,
          pageOpened,
          navigateWithinForum,
          navigateToPaidPlans,
          fetchPostPageData,
          incrementPostViewCount,
          setIsLoaded,
          setIsLoading,
          fetchCategoryPosts,
          fetchSubcategories,
          subscribeToCategory,
          unsubscribeFromCategory,
          subscribeToPost,
          unsubscribeFromPost,
          subscribeToAllCategories,
          unsubscribeFromAllCategories,
          pinPost,
          unpinPost,
          enablePostComments,
          disablePostComments,
          actionsOpened,
          actionsClosed,
          buttonClicked,
          removeItemFromUploadedRegistry,
          fetchOembed,
          createComment,
          createCommentReply,
          changePage,
          setPostsPageSorting,
          fetchReplies,
          fetchInitialReplies,
          startEditingComment,
          stopEditingComment,
          fetchCommentsWithReplies,
          fetchComment,
          clearComments,
          goBack,
          emitOpenCategory,
          emitCloseCategory,
          emitTypingThrottled,
          clearTypingUsers,
          emitClosePost,
          emitOpenPost,
          fastFormSubmitCallback,
          setSearchPageSorting,
          setHasReportedAppLoaded,
          fileDownloadRequest,
          userEventsMakeCategoryChildOrParent,
          userEventsShowEmptyState,
          userEventsReplyOrCommentIntent,
          userEventsClickPublish,
          userEventsClickLike,
          userEventsClickProfileLink,
          userEventsClickCreatePost,
          userEventsClickCreatePostButton,
          userEventsGuidelinesShown,
          userEventsPostingDisabledClose,
          userEventsSearchFocused,
          userEventsSearchCleared,
          userEventsSearchSuggestionsLoaded,
          userEventsSearchSubmitted,
          requestLogin,
          setSavedAction,
          ...modalActions,
          ...messageActions,
          manageCategoryMembersRemoveSiteMembers,
          manageCategoryMembersAddSiteMembers,
          assignMembersToGroup,
          deleteMembersFromGroup,
          fetchSiteMembersList,
          resetSiteMembersList,
          fetchMembersGroupsList,
          fetchBadges,
          fetchPaidPlans,
          categoryAddGroups,
          categoryRemoveGroups,
          setPostTypeFilter,
          setCategoryFilter,
          resetPostTypeFilter,
          clearSearchResults,
          upvoteComment,
          downvoteComment,
          unvoteComment,
          triggerOfflineMigration,
          fetchCategoriesGroups,
          fetchSimilarPosts,
          resetLikesData,
          markPostsAsRead,
          fetchExternalLinks: fetchExternalLinks(onFetchExternalLinksHook),
          fetchLocalStorage,
          setLocalStorageItem,
          hideGuidelines,
          showGuidelines,
          startLoadingCategory,
          navigateToMainPage,
          updateForumData,
          togglePosting,
          markPostAsRead,
          markPostAsUnread,
          resetPagerState,
        },
        store.dispatch,
      ),
      sentry,
    ),
    setBiLocation: BiLocationTracker.set,
  };
};

export const createControllerPromisifiedActions = ({
  store,
  sentry,
}: {
  store: Store;
  sentry: Sentry;
}) => {
  return {
    ...decorateActionsWithLogger(
      bindActionCreators(
        {
          createCommentPromisified: createCommentPromisified(onBeforeCommentCreateHook),
          createCommentReplyPromisified: createCommentReplyPromisified(onBeforeCommentCreateHook),
          fetchRepliesToPagePromisified,
          resolveCommentDeepLinkUrlPromisified,
          createPostAndNavigateToItPromisified: createPostAndNavigateToItPromisified(
            onBeforePostCreateHook,
          ),
          updateCommentPromisified: updateCommentPromisified(onBeforeCommentUpdateHook),
          updatePostPromisified: updatePostPromisified(onBeforePostUpdateHook),
          requestFileDownloadUrlPromisified,
          requestLoginPromisified,
          fetchMembersGroupsListPromisified,
          fetchRepliesPromisified,
          fetchLikesDataPromisified,
          fetchSearchSuggestionsPromisified,
        },
        store.dispatch,
      ),
      sentry,
    ),
  };
};
